@font-face {
    font-family: "Avenir Next";
    src: url(../fonts/AvenirNext-Regular.woff2) format("woff2"), url(../fonts/AvenirNext-Regular.woff) format("woff");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal; }
@font-face {
    font-family: "Avenir Next";
    src: url(../fonts/AvenirNext-DemiBold.woff2) format("woff2"), url(../fonts/AvenirNext-DemiBold.woff) format("woff");
    font-weight: 500;
    font-style: normal;
    font-stretch: normal; }
@font-face {
    font-family: "DIN Condensed";
    src: url(../fonts/DINCondensedBold.woff2) format("woff2"), url(../fonts/DINCondensedBold.woff) format("woff");
    font-weight: 700;
    font-style: normal;
    font-stretch: normal; }

/*.demo.bandeau {
    height:150px;
    bottom:0 !important;
    top:auto !important;
}*/
.bandeau.ccContent {
    position: fixed;
    bottom: 0;
    z-index: 9999;
    background: #FFF;
    width: 100%;
}
.bandeau .fancybox-slide--html,
.bandeau .fancybox-slide--current,
.bandeau .fancybox-slide
{padding:0}
.bandeau .fancybox-content {padding:22px;}

.demo.ccContent {
    font-family: 'Avenir Next', sans-serif;
    font-weight: 400;
    max-width: 90%;
}
.demo.ccContent h3 {
    font-weight:500;
    text-transform: uppercase;
    color:#3f3c66;
}
.demo.ccContent .icadeFRButton {
    text-transform: uppercase;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
.demo.ccContent .btnBloc {text-align: center;}
.demo.ccContent .icadeFRButton:hover {cursor:pointer;}
.demo.ccContent .icadeFRButton.btn-primary {
    color: #fff;
    background-color: #0A246A;
    border-color: #0A246A;}
.demo.ccContent .icadeFRButton.btn-normal {
    color: #000;
    background-color: transparent;
    border-color: #EEE;}

